import {DOMParser} from "@xmldom/xmldom";

export type DymoConnectOptions = {
   host?: string,
   port?: number
}

export type DymoPrinter = {
   name: string,
   model: string,
   connected: boolean
}

export type TireTagData = {
   niv?: string,
   auto?: string,
   prenom?: string,
   nom?: string,
   emplacement?: string,
   note?: string,
   chaining?: string,
   marque?: string,
   usure?: string,
   spec?: string,
}
export type TireTagDataKey = keyof TireTagData;

class Index {

   private options: DymoConnectOptions
   private readonly url: string;

   constructor(options?: DymoConnectOptions) {
      this.options = {
         host: options?.host ?? '127.0.0.1',
         port: options?.port ?? 41951
      }
      this.url = `https://${this.options.host}:${this.options.port}/DYMO/DLS/Printing`;
   }


   public getPrinters = async (): Promise<DymoPrinter[]> => {
      const result: DymoPrinter[] = [];
      if (typeof process !== 'undefined' && process.env) {
         process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
      }
      const response = await fetch(`${this.url}/GetPrinters`, {
         mode: 'cors'
      });
      const data = await response.text();
      const parser = new DOMParser();
      const xml = parser.parseFromString(data, 'text/xml');
      const names = xml.getElementsByTagName('Name');
      const models = xml.getElementsByTagName('ModelName');
      const connections = xml.getElementsByTagName('IsConnected');


      for (let i = 0; i < names.length; i++) {
         result.push({
            name: names[i].childNodes[0].nodeValue ?? '',
            model: models[i].childNodes[0].nodeValue ?? '',
            connected: connections[i].childNodes[0].nodeValue === 'True',
         });
      }
      return result;
   }

   public renderLabel = async (xml: string) : Promise<string> => {

         let body = `labelXml=${xml}`;
         if (typeof process !== 'undefined' && process.env) {
            process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
         }
         const response = await fetch(`${this.url}/RenderLabel`, {
            body,
            method: 'POST',
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded',
            },
         });
         const responseText = await response.text();
         const result = 'data:image/png;base64,' + responseText.slice(1, -1);
         return result;
   }
   public printLabel = async (printer: string, xml: string, copies: number = 1) : Promise<void> => {

         const printParams = `
         <LabelWriterPrintParams>
            <Copies>${copies}</Copies>
         </LabelWriterPrintParams>
`;

         let body = `printerName=${encodeURIComponent(printer)}&labelXml=${encodeURIComponent(xml)}&printParamsXml=${encodeURIComponent(printParams)}`;

         if (typeof process !== 'undefined' && process.env) {
            process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
         }
         const response = await fetch(`${this.url}/PrintLabel`, {
            body,
            method: 'POST',
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded',
            },
         });

         const result = await response.text();
         if (result !== 'true')
            throw result;
   }





   public getLabelSet = (data : TireTagData) => {
      return `
<LabelSet>
   <LabelRecord>
      <ObjectData Name="NIV">${data.niv??''}</ObjectData>
      <ObjectData Name="AUTO">${data.auto??''}</ObjectData>
   </LabelRecord>
</LabelSet>`
   }

}

export default Index;
