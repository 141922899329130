import * as React from 'react';
import clsx                         from 'clsx';
import { Theme, styled }            from '@mui/material/styles';
import TableCell                    from '@mui/material/TableCell';
import Paper                        from '@mui/material/Paper';
import {
   AutoSizer,
   Column,
   Table,
   TableCellRenderer,
   TableHeaderProps,
}                                   from 'react-virtualized';
import {RowMouseEventHandlerParams} from "react-virtualized/dist/es/Table";

const classes = {
   flexContainer: 'ReactVirtualizedDemo-flexContainer',
   tableRow: 'ReactVirtualizedDemo-tableRow',
   tableRowHover: 'ReactVirtualizedDemo-tableRowHover',
   tableRowSelected: 'ReactVirtualizedDemo-tableRowSelected',
   tableCell: 'ReactVirtualizedDemo-tableCell',
   noClick: 'ReactVirtualizedDemo-noClick',
};

const styles = ({ theme }: { theme: Theme }) =>
   ({
      // temporary right-to-left patch, waiting for
      // https://github.com/bvaughn/react-virtualized/issues/454
      '& .ReactVirtualized__Table__headerRow': {
         ...(theme.direction === 'rtl' && {
            paddingLeft: '0 !important',
         }),
         ...(theme.direction !== 'rtl' && {
            paddingRight: undefined,
         }),
      },
      [`& .${classes.flexContainer}`]: {
         display: 'flex',
         alignItems: 'center',
         boxSizing: 'border-box',
      },
      [`& .${classes.tableRow}`]: {
         cursor: 'pointer',
      },
      [`& .${classes.tableRowSelected}`]: {
            backgroundColor: '#717bbdb5',

      },
      [`& .${classes.tableRowHover}`]: {
         '&:hover': {
            backgroundColor: theme.palette.grey[200],
         },
      },
      [`& .${classes.tableCell}`]: {
         flex: 1,
      },
      [`& .${classes.noClick}`]: {
         cursor: 'initial',
      },
   } as const);

export interface ColumnData {
   dataKey: string;
   label: string;
   numeric?: boolean;
   width: number;
}

interface Row {
   index: number;
}

interface VirtualizedTableProps<T> {
   columns: readonly ColumnData[];
   headerHeight?: number;
   onRowClick?: ((info: RowMouseEventHandlerParams) => void) | undefined;
   rowCount: number;
   rowGetter: (row: Row) => T;
   rowHeight?: number;
   selectedIndex?: number
}

class VirtualizedTable<T> extends React.PureComponent<VirtualizedTableProps<T>> {
   static defaultProps = {
      headerHeight: 48,
      rowHeight: 48,
   };

   getRowClassName = ({ index }: Row) => {
      const { onRowClick, selectedIndex } = this.props;

      return clsx(classes.tableRow, classes.flexContainer, {
         [classes.tableRowHover]: index !== -1 && onRowClick != null && selectedIndex===undefined,
         [classes.tableRowSelected]: selectedIndex!==undefined &&  index === selectedIndex,
      });
   };

   cellRenderer: TableCellRenderer = ({ cellData, columnIndex }) => {
      const { columns, rowHeight, onRowClick } = this.props;
      return (
         <TableCell
            component="div"
            className={clsx(classes.tableCell, classes.flexContainer, {
               [classes.noClick]: onRowClick == null,
            })}
            variant="body"
            style={{ height: rowHeight }}
            align={
               (columnIndex != null && columns[columnIndex].numeric) || false
                  ? 'right'
                  : 'left'
            }
         >
            {cellData}
         </TableCell>
      );
   };

   headerRenderer = ({
                        label,
                        columnIndex,
                     }: TableHeaderProps & { columnIndex: number }) => {
      const { headerHeight, columns } = this.props;

      return (
         <TableCell
            component="div"
            className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
            variant="head"
            style={{ height: headerHeight }}
            align={columns[columnIndex].numeric || false ? 'right' : 'left'}
         >
            <span>{label}</span>
         </TableCell>
      );
   };

   render() {
      const { columns, rowHeight, headerHeight, ...tableProps } = this.props;
      return (
         <AutoSizer >
            {({ height, width }) => (
               <Table
                  height={height}
                  width={width*5}
                  rowHeight={rowHeight!}
                  gridStyle={{
                     direction: 'inherit',
                  }}
                  headerHeight={headerHeight!}
                  {...tableProps}
                  rowClassName={this.getRowClassName}
               >
                  {columns.map(({ dataKey, ...other }, index) => {
                     return (
                        <Column
                           key={dataKey}
                           headerRenderer={(headerProps) =>
                              this.headerRenderer({
                                 ...headerProps,
                                 columnIndex: index,
                              })
                           }
                           className={classes.flexContainer}
                           cellRenderer={this.cellRenderer}
                           dataKey={dataKey}
                           {...other}
                        />
                     );
                  })}
               </Table>
            )}
         </AutoSizer>
      );
   }
}

export default styled(VirtualizedTable)(styles);
