import React, {
   useEffect,
   useRef,
   useState
} from 'react';
import './App.css';

import tireTag             from './resources/TireTag.dymo';
import readXlsxFile, {Row} from 'read-excel-file'

// @ts-ignore
import DymoConnect, {
   TireTagData,
   TireTagDataKey
}                                     from './DymoConnect';
import {
   alpha,
   AppBar,
   Box,
   CircularProgress,
   FormControl,
   IconButton,
   InputBase,
   MenuItem,
   Select,
   styled,
   Toolbar,
   Typography
}                                     from "@mui/material";
import SearchIcon                     from '@mui/icons-material/Search';
import FileOpenIcon                   from '@mui/icons-material/FileOpen';
import PrintIcon                      from '@mui/icons-material/Print';
import {
   debounce,
   forEach
}                                     from "lodash";
import VirtualizedTable, {ColumnData} from "./VirtualizedTable";

import Excel    from 'exceljs';

const Container = styled(Box)`
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
`;

const Search = styled('div')(({theme}) => ({
   position: 'relative',
   borderRadius: theme.shape.borderRadius,
   backgroundColor: alpha(theme.palette.common.white, 0.15),
   '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
   },
   marginLeft: 0,
   width: '100%',
   [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
   },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
   padding: theme.spacing(0, 2),
   height: '100%',
   position: 'absolute',
   pointerEvents: 'none',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
   color: 'inherit',
   '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
         width: '12ch',
         '&:focus': {
            width: '20ch',
         },
      },
   },
}));

type HeaderMap = {
   name: string,
   index: number
}

const AppBarSelect = styled(Select<string>)`
  color: white;

  &:before {
    border-bottom-color: white;
  }

  &.MuiInputBase-root:hover {
    &:before {
      border-bottom-color: white;
    }
  }

  &.MuiSvgIcon-root {
    fill: white;
  }

`

function App() {
   const [printers, setPrinters] = useState<string[]>([]);
   const [selectedPrinter, setSelectedPrinter] = useState("");
   const fileInputRef = useRef<HTMLInputElement>(null);
   const [rows, setRows] = useState<Row[]>([]);
   const [headers, setHeaders] = useState<any[]>([]);
   const [tableRows, setTableRows] = useState<any[]>([]);
   const [headerMap, setHeaderMap] = useState<HeaderMap[]>([]);
   const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
   const [loading, setLoading] = useState<boolean>(false);
   const [worksheet, setWorksheet] = useState<Excel.Worksheet|null>(null);
   const [columns, setColumns] = useState<ColumnData[]>([]);
   const [rowCount, setRowCount] = useState<number>(0);

   useEffect(() => {
      const dymo = new DymoConnect();
      dymo.getPrinters()
          .then(printers => {
             setPrinters(printers.map(printer => printer.name));
          })
      return () => {

      };
   }, []);

   useEffect(() => {
      if (worksheet) {
         resolveExcelHeaders();
         console.log(worksheet.rowCount)
         search();
      } else {
         setHeaderMap([]);
         setColumns([]);
         setRowCount(0);
      }
      return () => {

      };
   }, [worksheet]);



   const setFile = (file: File) => {

      const wb = new Excel.Workbook()
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
         const buffer = reader.result;
         if (buffer instanceof ArrayBuffer) {
            wb.xlsx.load(buffer)
              .then(() => {
                 console.log(wb.worksheets);
                    setWorksheet(wb.worksheets[0]);
                 }
              );
         }
      }


      return;
      setLoading(true);
      readXlsxFile(file)
         .then((rows) => {
            setHeaders(rows[0].map(row => row));
            setHeaderMap(rows[0].map((cell, index) => {
               return {index, name: cell as string}
            }))
            rows.shift();
            setRows([...rows]);
            setTableRows([...rows]);
            setLoading(false);
         })
   }

   const loadLabel = async (tireTagData: TireTagData): Promise<string> => {
      const req = await fetch(tireTag);
      let tagXml = await req.text();
      ['NIV', 'AUTO', 'NOTE', 'MARQUE', 'USURE', 'SPEC', 'EMPLACEMENT', 'CHAINING', 'PRENOM', 'NOM'].forEach(field => {
         const value = tireTagData[field.toLowerCase() as TireTagDataKey] ?? ''
         tagXml = tagXml.replace(`%%${field}%%`, value);
      });
      return tagXml
   }

   const search = (searchStr?: string) => {
      setSelectedIndex(undefined);
      if (worksheet) {
         let results = [];
         const count = worksheet.rowCount;
         for (let i = 1; i <= count; i++) {

            const row = getRow(i, searchStr);
            if (row) {
               results.push(row);
            }
         }
         setRowCount(results.length);
         setTableRows(results);
      }
   }

   const debouncedSearch = debounce(search, 300);

   const printLabel = async () => {
      if (selectedIndex !== undefined && selectedPrinter && selectedIndex < tableRows.length) {
         const selectedRow = getTableRow(selectedIndex);
         const data: TireTagData = {
            niv: selectedRow.niv,
            nom: selectedRow.nomClient,
            prenom: selectedRow.prenomClient,
            auto: selectedRow.vehicule,
            note: selectedRow.note,
            emplacement: `${selectedRow.range}:${selectedRow.colonne}:${selectedRow.hauteur}`,
            marque: selectedRow.marque,
            usure: selectedRow.usure,
            spec: selectedRow.specificationPneus,
         }
         const copies = parseInt(selectedRow.quantiteTotale) ?? 1;
         const labelXml = await loadLabel(data)
         const dymo = new DymoConnect();
         dymo.printLabel(selectedPrinter, labelXml, copies);
      }
   }

   const getCellValue = (row: Row, columnName: string): string => {
      const map = headerMap.find(hm => hm.name === columnName);
      if (map) {
         return row[map.index]?.toString() ?? '';
      }
      return '';
   }

   const getRow = (index: number, filter?: string) => {
      if (worksheet) {
         const item = {};
         const row = worksheet.getRow(index+1);
         let filterMatch = false;
         headerMap.forEach(map => {
            const cell = row.getCell(map.index);
            const value = cell.text;
            if (filter && value.toLowerCase().includes(filter.toLowerCase()))
               filterMatch = true;
            const isDate = Object.prototype.toString.call(value) === '[object Date]';
            // @ts-ignore
            item[map.name] = (value && typeof value === 'object') ? isDate ? `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}` : value.toString() : value;
         });
         if (filter && !filterMatch)
            return undefined;
         return item;
      }
   }

   const getTableRow = (index: number) => {
      return tableRows[index];
   }

   const resolveExcelHeaders = () => {
      const headers: HeaderMap[] = [];
      if (worksheet) {
         const columnCount = worksheet.columnCount;
         const columns:ColumnData[] = []
         const firstRow = worksheet.getRow(1);
         for (let i = 1; i <= columnCount; i++) {
            const column = worksheet!.getColumn(i);
            const cell = firstRow.getCell(i)
            const charCount = (cell.text?.length??0) > (column.width??0)  ?  cell.text?.length : column.width ?? 15;

            columns.push({
               label: cell.text,
               dataKey: cell.text,
               width: (charCount)*12
            })
            headerMap.push({name:cell.text, index: i});
         }
         setColumns(columns);
         setHeaderMap(headerMap);
      }
   }

   const onRowClick = (index: number) => {
      setSelectedIndex(index)
   }

   return (
      <Container>
         <AppBar position="static">
            <Toolbar>
               <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  sx={{mr: 2}}
                  onClick={() => fileInputRef.current?.click()}
               >
                  <FileOpenIcon/>
               </IconButton>
               <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}
               >
                  Tire label Printer {rows.length ? '(Ready)' : ''}
               </Typography>
               <FormControl variant="standard" sx={{p: 1}}>
                  <AppBarSelect
                     value={selectedPrinter}
                     onChange={(e => setSelectedPrinter(e.target.value))}
                     sx={{color: 'white'}}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {printers.map(printer =>
                        <MenuItem value={printer} key={printer}>{printer}</MenuItem>
                     )}
                  </AppBarSelect></FormControl>
               {/*       <select value={selectedPrinter} onChange={(e => setSelectedPrinter(e.target.value))}>*/}
               {/*          <option value=""></option>*/}
               {/*          {printers.map(printer =>*/}
               {/*             <option value={printer} key={printer}>{printer}</option>*/}
               {/*          )}*/}
               {/*       </select>*/}
               <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  sx={{mr: 2}}
                  onClick={() => printLabel()}
                  disabled={selectedIndex === undefined || !selectedPrinter}
               >
                  <PrintIcon/>
               </IconButton>
               <Search>
                  <SearchIconWrapper>
                     <SearchIcon/>
                  </SearchIconWrapper>
                  <StyledInputBase
                     placeholder="Search…"

                     inputProps={{'aria-label': 'search'}}
                     onChange={(e) => debouncedSearch(e.target.value)}
                  />
               </Search>
            </Toolbar>
         </AppBar>
         {loading && <CircularProgress/>}
         {
            !loading  &&
            <Box width='100vw' height='100%' sx={{overflowX: 'scroll'}}>
               <VirtualizedTable
                  rowCount={rowCount}
                  rowGetter={({index}) => getTableRow(index)}
                  columns={columns}
                  onRowClick={(e) => onRowClick(e.index)}
                  selectedIndex={selectedIndex}
               />
            </Box>
            //    <TableContainer>
            //    <Table>
            //       <TableHead><TableRow>
            //          {headers.map(header=>
            //             <TableCell key={header}>
            //                {header}
            //             </TableCell>
            //             )}
            //             </TableRow>
            //       </TableHead>
            //       <TableBody>
            //          {tableRows.map((row, row_idx)=>
            //          <TableRow
            //             key={row_idx}
            //             onClick={()=>handleRowClick(row)}
            //             hover
            //             sx={{cursor: 'pointer', backgroundColor:row===selectedRow?'#c5dffa':undefined}}
            //          >
            //             {row.map((cell, cell_idx)=>
            //             <TableCell key={`${row_idx}_${cell_idx}`}>
            //                {cell?.toString()??''}
            //             </TableCell>
            //             )}
            //          </TableRow>
            //          )}
            //       </TableBody>
            //    </Table>
            // </TableContainer>
         }
         <input hidden type="file" ref={fileInputRef} value='' onChange={e => {
            if (e.target.files && e.target.files[0])
               setFile(e.target.files[0]);
         }}/>
      </Container>
      // <div className="App">
      //    <header className="App-header">
      //       <img src={logo} className="App-logo" alt="logo"/>
      //       <p>
      //          Edit <code>src/App.tsx</code> and save to reload.
      //       </p>
      //       <input hidden type="file" ref={fileInputRef} onChange={e => {
      //          if (e.target.files && e.target.files[0])
      //             setFile(e.target.files[0]);
      //       }}/>
      //       <button onClick={()=>fileInputRef.current?.click()}>Open file</button>
      //
      //       <div>
      //          <input disabled={searching} type="text" ref={searchRef}/><button onClick={()=>search()}>{searching?'Recherche en cours...':'Rechercher'}</button>
      //       </div>
      //       <select value={selectedPrinter} onChange={(e => setSelectedPrinter(e.target.value))}>
      //          <option value=""></option>
      //          {printers.map(printer =>
      //             <option value={printer} key={printer}>{printer}</option>
      //          )}
      //       </select>
      //       <button onClick={onTestClick}>TEST</button>
      //       <a
      //          className="App-link"
      //          href="https://reactjs.org"
      //          target="_blank"
      //          rel="noopener noreferrer"
      //       >
      //          Learn React
      //       </a>
      //       {imgSrc && <img src={imgSrc}/>}
      //    </header>
      // </div>
   );
}

export default App;
